import React from 'react';
import Layout from '../layouts/layout';
import { Link } from 'gatsby';

export default function TurkeySuccess() {
	return (
		<Layout title="Order Successful">
			<h1>Order Successful</h1>
			<p>Your Thanksgiving order was received! Here's what to expect</p>
			<ol>
				<li>
					Today, you should receive an order confirmation, confirming
					that your payment was accepted.
				</li>
				<li>
					On the week of Thanksgiving, we'll contact you at your email
					address to confirm a pickup time.
				</li>
			</ol>

			<p>
				If you have any questions, see our{' '}
				<Link to="/turkey-faq">Turkey FAQ</Link>, or call us at{' '}
				<a href="tel:+17602957993">(760) 295-7993</a>
			</p>
		</Layout>
	);
}
